import { useMutation } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'

import { API_ENDPOINT } from '@unreserved-frontend-v2/api/constants'
import {
  GetAutocompletePlaceResultDocument,
  GetAutocompletePlaceResultQuery,
} from '@unreserved-frontend-v2/api/generated/graphql/get-autocomplete-place-result.graphql'

export function useGetAutocompletePlaceResult() {
  const mutation = useMutation(({ placeId, sessionToken }: { placeId: string; sessionToken: string }) => {
    const variables = {
      geoResultInput: { addressAutocompleteResult: { placeId, sessionToken } },
    }

    const client = new GraphQLClient(API_ENDPOINT)
    return client.request<GetAutocompletePlaceResultQuery>(GetAutocompletePlaceResultDocument, variables)
  })

  return mutation
}
