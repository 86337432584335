import { faHouseChimney, faLocationCrosshairs } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import debounce from 'lodash/debounce'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  AddressSuggestion,
  DistanceUnit,
  GeocodedAddress,
  Place,
} from '@unreserved-frontend-v2/api/generated/graphql/types'
import { useGetAutocompletePlaceResult } from '@unreserved-frontend-v2/api/hooks/use-get-autocomplete-place-result'
import { useGetGeosearchResults } from '@unreserved-frontend-v2/api/hooks/use-get-geosearch-results'
import { BaseDropdown, Option } from '@unreserved-frontend-v2/ui/base-dropdown/base-dropdown'
import { FilterChangeEvent } from '@unreserved-frontend-v2/ui/schema-table/models'
import { useTailwindConfig } from '@unreserved-frontend-v2/ui/tailwind/use-tailwind-config'
import { NoOp } from '@unreserved-frontend-v2/utils/noop'

import { GeosearchResult } from './geosearch-result'
import { addressToString, mapAutoCompleteSuggestionToInfo } from './utils'
import { DEFAULT_ADDRESS_SEARCH_RADIUS } from '../map/constants'
import { Coordinate } from '../map/coordinate'
import { preciseLngOrLat } from '../map/utils-lite'
import { GetPositionResultCode, useGeolocation } from '../../hooks/geolocation'
import { GeoInputForUI } from '../../types'

export interface GeosearchResultsProps {
  className?: string
  searchSession: string
  forMobile: boolean
  keyword: string
  displayValue: string
  location: GeoInputForUI | null
  showCurrentLocationSelector?: boolean
  onChange: (event: FilterChangeEvent | FilterChangeEvent[]) => void
  onMyPositionChange: () => void
  onAddressSelected: (address: GeocodedAddress) => void
  onPlaceSelected: (place: Partial<Place>) => void
  onResultCountsChanged?: (howMany: number) => void
}

export const DEFAULT_NEARBY_LISTINGS_RADIUS = 25
const LOOKUP_DEBOUNCE = 250

export const DISTANCE_FILTER_VALUES: Option[] = [
  { name: '100m', id: '0.1' },
  { name: '500m', id: '0.5' },
  { name: '1KM', id: '1' },
  { name: '5KM', id: '5' },
  { name: '10KM', id: '10' },
  { name: '25KM', id: '25' },
  { name: '50KM', id: '50' },
  { name: '75KM', id: '75' },
  { name: '100KM', id: '100' },
]

export function GeosearchResults({
  forMobile,
  searchSession,
  keyword,
  displayValue,
  location,
  onChange,
  onMyPositionChange,
  onAddressSelected,
  onPlaceSelected,
  onResultCountsChanged = NoOp,
  showCurrentLocationSelector = true,
}: GeosearchResultsProps) {
  const { t } = useTranslation('geolocation')
  const { locationAvailable, locationDenied, getCurrentPosition } = useGeolocation()
  const [addresses, setAddresses] = useState<AddressSuggestion[]>([])
  const [places, setPlaces] = useState<Partial<Place>[]>([])
  const isInitialRender = useRef<boolean>(true)
  const [keepPreviousData, setKeepPreviousData] = useState<boolean>(false)

  const [listingsRadius, setListingsRadius] = useState<number>(
    location?.distance ? location.distance.value : DEFAULT_NEARBY_LISTINGS_RADIUS
  )

  const [selectedCoord, setSelectedCoord] = useState<Coordinate | null>(
    location ? { lat: location.latitude, lng: location.longitude } : null
  )

  const {
    addresses: addressesData,
    places: placesData,
    refetch: refetchAddresses,
    isRefetching: refetchingAddresses,
  } = useGetGeosearchResults(keyword, searchSession, { keepPreviousData, enabled: false })

  const { mutateAsync: getPlaceResults } = useGetAutocompletePlaceResult()

  useEffect(() => {
    const data = addressesData || []
    setAddresses(data as AddressSuggestion[])
    onResultCountsChanged(data.length + places.length)
  }, [addressesData, onResultCountsChanged, places.length, refetchingAddresses])

  useEffect(() => {
    const placesArray = mapAutoCompleteSuggestionToInfo(placesData)

    setPlaces(placesArray)
    onResultCountsChanged(placesArray.length + addresses.length)
  }, [addresses.length, onResultCountsChanged, placesData])

  // Because we debounce refetching the addresses, there is a race condition where you can set the keyword to
  // an empty string. By holding down the DELETE key, the refetch will be triggered with one char remaining in
  // the string but refetch won't happen for the debounced time which means that by the time it's called the
  // value is now an empty string. Simply ignore the refetch if we detect an empty string.
  const ignoreAddressesRefetch = useRef<boolean>(false)

  const fetchAddresses = useMemo(() => {
    return debounce(() => {
      if (ignoreAddressesRefetch.current) {
        return
      }

      refetchAddresses()
    }, LOOKUP_DEBOUNCE)
  }, [refetchAddresses])

  // NOTE: Had to move this to its own useEffect() because having it in the effect below was causing the coord
  //       to be cleared and then the distance change event was not firing.
  useEffect(() => {
    if (isInitialRender.current) {
      return
    }

    // As soon as we type into the input we lose the selected location and a query will re-run to get new locations.
    setSelectedCoord(null)

    if (keyword.trim() !== '') {
      ignoreAddressesRefetch.current = false
      fetchAddresses()
      setKeepPreviousData(true)
    }
  }, [fetchAddresses, keyword])

  useEffect(() => {
    if (isInitialRender.current) {
      return
    }

    if (keyword.trim() === '' && displayValue.trim() === '') {
      ignoreAddressesRefetch.current = true
      setPlaces([])
      setAddresses([])
      onChange([
        { key: 'geo', value: null },
        { key: 'placeSlugs', value: undefined },
        { key: 'bounds', value: undefined },
      ])
      setKeepPreviousData(false)
    }
  }, [addressesData, keyword, onChange, displayValue])

  function getGeoChangeEvent(lat: number, lng: number, distance: number, displayAddress: string): FilterChangeEvent[] {
    const event = {
      key: 'geo',
      value: {
        latitude: preciseLngOrLat(lat),
        longitude: preciseLngOrLat(lng),
        displayAddress,
        distance: {
          value: distance,
          unit: DistanceUnit.Kilometers,
        },
      },
    }

    return [event, { key: 'sort', value: 'NearToFar' }]
  }

  async function handleAddressSelected(addressSuggestion: AddressSuggestion) {
    try {
      const { geo } = await getPlaceResults({ placeId: addressSuggestion.placeId, sessionToken: searchSession })
      if (!geo || geo.length === 0) {
        throw new Error('No geo data returned for selected place')
      }
      const address = geo[0] as GeocodedAddress
      const { latitude: lat, longitude: lng } = address
      setSelectedCoord({ lat, lng })
      onChange([
        { key: 'placeSlugs', value: undefined },
        { key: 'bounds', value: undefined },
        ...getGeoChangeEvent(lat, lng, listingsRadius || DEFAULT_ADDRESS_SEARCH_RADIUS, addressToString(address)),
      ])
      onAddressSelected(address)
    } catch (e) {
      // TODO: determine what to do if we can't get the address
      console.error(e)
    }
  }

  function handlePlaceSelected(place: Partial<Place>) {
    setSelectedCoord(null)
    onChange([
      { key: 'geo', value: null },
      { key: 'placeSlugs', value: place.slug },
      { key: 'bounds', value: undefined },
    ])
    onPlaceSelected(place)
  }

  function handleGetMyPosition(code: GetPositionResultCode, message?: string, coord?: Coordinate) {
    if (code === 'success') {
      setSelectedCoord(coord || null)

      if (coord) {
        onChange([
          { key: 'placeSlugs', value: undefined },
          ...getGeoChangeEvent(
            coord.lat,
            coord.lng,
            DEFAULT_ADDRESS_SEARCH_RADIUS,
            `${t('location-selector.current-location')}`
          ),
        ])

        onMyPositionChange()
      } else {
        // TODO: Display a message to the user that the lookup failed. Confirm with Dima/Jon what to display.
      }
    } else {
      // TODO: Display a message to the user that the lookup failed. Confirm with Dima/Jon what to display.
    }
  }

  function handleMyLocationClick() {
    if (!locationAvailable || locationDenied) {
      alert(t('location-selector.check-settings'))
      return
    }

    getCurrentPosition(handleGetMyPosition)
  }

  function handleDistanceChange(value: string) {
    setListingsRadius(Number.parseFloat(value))

    if (selectedCoord) {
      onChange(getGeoChangeEvent(selectedCoord.lat, selectedCoord.lng, Number.parseFloat(value), displayValue))
    }
  }

  useEffect(() => {
    isInitialRender.current = false
  }, [])

  const getMobileDropdownMaxHeight = (): string => {
    if (addresses.length === 2) {
      return '290px'
    } else if (addresses.length === 3) {
      return '250px'
    }
    return '300px'
  }

  return (
    <div
      className={twMerge(
        `w-full overflow-y-visible rounded-b-[5px]`,
        `${forMobile ? 'min-h-[80px]' : 'min-h-[140px]'}`,
        `${!showCurrentLocationSelector ? 'min-h-[0px]' : ''}`
      )}
      style={{
        maxHeight: forMobile ? getMobileDropdownMaxHeight() : '600px',
      }}
    >
      <div className="flex flex-col rounded-[5px] bg-shades-25 md:pt-[53px]">
        {forMobile ? null : (
          <div
            className={twMerge(
              'absolute top-[1px] left-[1px] right-[1px] z-10 flex flex-col justify-center bg-white pl-6 text-sm font-bold uppercase',
              'border-b border-b-shades-200',
              'h-[53px] rounded-tr-[5px] rounded-tl-[5px] pr-6'
            )}
          >
            {t('geolocation:location')}
          </div>
        )}

        <div className="flex flex-col border-shades-200 bg-shades-25 pl-6">
          {/* if showCurrentLocationSelector is false, don't show distance select until we have addresses */}
          {showCurrentLocationSelector || addresses.length ? (
            <div className="mt-1 flex items-center text-sm font-bold uppercase tracking-widest">
              {t('location-selector.listings-within')}
              <BaseDropdown
                onValueChange={handleDistanceChange}
                currentValue={String(listingsRadius)}
                className="border-none"
                dropdownClassName="w-[max-content] font-normal text-base normal-case tracking-normal"
                selectedOptionClassName="bg-primary-100"
                options={DISTANCE_FILTER_VALUES}
                omitNoneOption={true}
              />

              {t('location-selector.of')}
            </div>
          ) : null}
          <div className={twMerge('h-full overflow-y-auto', forMobile ? 'max-h-[200px]' : 'max-h-[500px]')}>
            {showCurrentLocationSelector ? (
              <div className={`mb-4 flex cursor-pointer items-center`} onClick={handleMyLocationClick}>
                <FontAwesomeIcon className={`pr-4`} icon={faLocationCrosshairs}></FontAwesomeIcon>
                {t('location-selector.my-location')}
              </div>
            ) : null}
            {addresses.map((a, index) => {
              return (
                <div key={`${index}`} className="mb-4 cursor-pointer" onClick={() => handleAddressSelected(a)}>
                  <div className={`flex`}>
                    <FontAwesomeIcon className={`pr-4 pt-1`} icon={faHouseChimney}></FontAwesomeIcon>
                    <div className="flex flex-col">
                      <div>{a.mainText}</div>
                      <div className="text-sm text-shades-300">{a.secondaryText}</div>
                    </div>
                  </div>
                </div>
              )
            })}

            {places.length > 0 ? (
              <>
                {' '}
                <div
                  className={twMerge(
                    'mt-2 flex items-center text-sm font-bold uppercase tracking-widest',
                    // need to adjust spacing above places when there is no current location or addresses
                    !showCurrentLocationSelector && !addresses.length ? 'pt-[7px]' : ''
                  )}
                >
                  {t('location-selector.show-all-listings')}
                </div>
                <div className={twMerge(forMobile ? '' : 'rounded-[5px]')}>
                  {places.map((p, index) => {
                    return (
                      <div
                        key={`${index}`}
                        className="mt-4 cursor-pointer pr-6 last:pb-4"
                        onClick={() => handlePlaceSelected(p)}
                      >
                        <GeosearchResult place={p} />
                      </div>
                    )
                  })}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeosearchResults
