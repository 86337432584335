import { useCallback, useEffect, useRef, useState } from 'react'

import { useClickOutside } from '@unreserved-frontend-v2/ui/hooks/use-click-outside/use-click-outside'

export function useDropdown() {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined)
  const [shouldFlip, setShouldFlip] = useState(false)
  const [isDrawerReady, setIsDrawerReady] = useState(false)

  useEffect(() => {
    if (dropdownRef.current) {
      // Set a reasonable default for maxHeight
      const defaultMaxHeight = window.innerHeight / 2
      const dropdownRect = dropdownRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - dropdownRect.bottom
      const dropdownHeight = dropdownRef.current.clientHeight

      if (spaceBelow < dropdownHeight) {
        // flip the dropdown if there's not enough space below to properly display it
        if (dropdownHeight + spaceBelow < window.innerHeight / 3) {
          setMaxHeight(defaultMaxHeight)
          setShouldFlip(true)
        }
        // Resize the dropdown if there is space but not enough to display all options
        else {
          setMaxHeight(dropdownHeight + spaceBelow - 20)
          setShouldFlip(false)
        }
      }
      // if the dropdown has enough space, display all options without limit
      else {
        setMaxHeight(undefined)
        setShouldFlip(false)
      }
    }
    setIsDrawerReady(true)
  }, [isOpen])

  // Close on click outside of dropdown
  useClickOutside(dropdownRef, () => setIsOpen(false))

  const handleToggle = useCallback(() => {
    setIsDrawerReady(false)
    setIsOpen((current) => !current)
  }, [])

  const handleDropdownEnterKey = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter') {
      setIsOpen((current) => !current)
    }
  }, [])

  const handleOptionEnterKey = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter') {
      setIsOpen(false)
    }
  }, [])

  return {
    dropdownRef,
    isOpen,
    maxHeight,
    shouldFlip,
    isDrawerReady,
    handleToggle,
    handleDropdownEnterKey,
    handleOptionEnterKey,
  }
}
