import { useEffect, useState } from 'react'
import { Coordinate } from '../components/map/coordinate'

export type GetPositionResultCode = 'success' | 'denied' | 'unavailable' | 'timeout'
export type GetPositionCallback = (code: GetPositionResultCode, message?: string, position?: Coordinate) => void

export function useGeolocation() {
  const [available, setAvailable] = useState<boolean>(false)
  const [denied, setDenied] = useState<boolean>(false)

  useEffect(() => {
    // NOTE:  We need to do this in a useEfect so that it runs client side or else it throws an error because
    //        navigator is not available on the server.
    setAvailable('geolocation' in navigator)
  }, [])

  function getCurrentPosition(onGetPosition: GetPositionCallback) {
    navigator.geolocation.getCurrentPosition(
      function getPositionSucceeded(position) {
        const { latitude: lat, longitude: lng } = position.coords
        onGetPosition('success', '', { lat, lng })
      },
      function getPositionFailed(error) {
        if (error.code === 1) {
          onGetPosition('denied', error.message)
          setDenied(true)
        } else if (error.code === 2) {
          onGetPosition('unavailable', error.message)
        } else if (error.code === 3) {
          onGetPosition('timeout', error.message)
        }
        // TODO: Log this to Datadog so that we can know about it.
      },
      {
        enableHighAccuracy: false,
        timeout: 5000,
      }
    )
  }

  return { locationAvailable: available, locationDenied: denied, getCurrentPosition }
}
