import { faLocationDot } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { v4 as uuidv4 } from 'uuid'

import { GeocodedAddress, Place } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { Button } from '@unreserved-frontend-v2/ui/button/button'
import { useClickOutside } from '@unreserved-frontend-v2/ui/hooks/use-click-outside/use-click-outside'
import { MobileDropdown } from '@unreserved-frontend-v2/ui/mobile-dropdown/mobile-dropdown'
import { FilterChangeEvent } from '@unreserved-frontend-v2/ui/schema-table/models'
import { NoOp } from '@unreserved-frontend-v2/utils/noop'

import { GeosearchResults } from './geosearch-results'
import { addressToString } from './utils'
import { GeoInputForUI } from '../../types'

const FontAwesomeIcon: React.ComponentType<FontAwesomeIconProps> = dynamic(
  () => import('@fortawesome/react-fontawesome').then((pkg) => pkg.FontAwesomeIcon),
  {
    ssr: false,
  }
)

export enum GeoSearchVariant {
  DEFAULT = 'default',
  GREEN = 'green',
}

export const variants = {
  [GeoSearchVariant.DEFAULT]: {
    iconClassName: '',
    clearClassName: '',
  },
  [GeoSearchVariant.GREEN]: {
    iconClassName: 'text-primary',
    clearClassName: 'text-primary',
  },
}

export interface GeosearchFilterProps {
  className?: string
  forMobile: boolean
  secondaryFiltersOpen?: boolean
  location: GeoInputForUI | null
  searchKeywordPlaceholder?: string
  onMainFilterToggled?: (isOpen: boolean) => void
  onChange: (event: FilterChangeEvent | FilterChangeEvent[]) => void
  isClearable?: boolean
  // supporting both className and containerClasses as filters can pass in either
  containerClasses?: string
  showCurrentLocationSelector?: boolean
  variant?: GeoSearchVariant
  dropdownContainerClassName?: string
}

export function GeosearchFilter({
  className = '',
  forMobile = false,
  secondaryFiltersOpen = false,
  location = null,
  searchKeywordPlaceholder,
  onChange,
  onMainFilterToggled = NoOp,
  isClearable,
  containerClasses,
  showCurrentLocationSelector = true,
  variant = GeoSearchVariant.DEFAULT,
  dropdownContainerClassName,
}: GeosearchFilterProps) {
  const { t } = useTranslation('geolocation')
  const [searchSessionId, setSearchSessionId] = useState(uuidv4())
  const [showResults, setShowResults] = useState<boolean>(false)
  const ignoreClickAway = useRef<boolean>(false)
  const dropMenuRef = useRef<HTMLDivElement>(null)
  const inputContainerRef = useRef<HTMLDivElement>(null)
  const [resultsCount, setResultsCount] = useState<number>(0)
  // when showCurrentLocationSelector is false, the dropdown is only open if there are results
  // so we need to check these 3 values to know for sure that the dropdown is open in all configurations
  const isDropdownOpen = showResults && (!!resultsCount || showCurrentLocationSelector)
  const variantConfig = variants[variant]

  // Need two values here. "keyword" is the one used to actually do the search. "displayValue" is used
  // to show the value in the input field. This comes into play when we select an address and want to
  // update the text in the input field but NOT cause another address search to happen.
  const [displayValue, setDisplayValue] = useState<string>(location?.displayAddress || '')
  const [keyword, setKeyword] = useState<string>(location?.displayAddress || '')
  const canClear = isClearable && keyword && showResults

  // Takes care of updating the display name in the search box when the location changes (e.g. - from the URL).
  useEffect(() => {
    const newValue = searchKeywordPlaceholder || location?.displayAddress || ''
    setDisplayValue(newValue)
    setKeyword(newValue)
  }, [location, searchKeywordPlaceholder])

  useEffect(() => {
    if (secondaryFiltersOpen) {
      onMainFilterToggled(false)
      setShowResults(false)
    }
  }, [secondaryFiltersOpen, onMainFilterToggled])

  useEffect(() => {
    // TODO: Investigate if this will help:
    // https://mui.com/material-ui/getting-started/faq/#why-do-the-fixed-positioned-elements-move-when-a-modal-is-opened
    if (showResults && !forMobile) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [showResults, forMobile])

  const handleResultCountsChanged = useCallback(
    (howMany: number) => {
      setResultsCount(howMany)
    },
    [setResultsCount]
  )

  function handleLocationButtonClick(): void {
    setShowResults((isOpen: boolean) => !isOpen)
    onMainFilterToggled(!showResults)
  }

  const handleDropdownCloseResults = useCallback(() => {
    onMainFilterToggled(false)
    setShowResults(false)
  }, [onMainFilterToggled])

  function handleKeywordFocus() {
    onMainFilterToggled(true)
    setShowResults(true)
  }

  function handleClickAwayFromResults() {
    if (ignoreClickAway.current) {
      return
    }

    onMainFilterToggled(false)
    setShowResults(false)
  }

  useClickOutside(dropMenuRef, handleClickAwayFromResults)

  function handleKeywordChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target

    setDisplayValue(value)
    setKeyword(value)

    // As soon as we type into the input we lose the selected location and a query will re-run to get new locations.
    onMainFilterToggled(true)
    setShowResults(true)
  }

  const handleAddressSelected = useCallback(
    (address: GeocodedAddress) => {
      setDisplayValue(addressToString(address))
      onMainFilterToggled(false)
      setShowResults(false)
      setSearchSessionId(uuidv4())
    },
    [onMainFilterToggled]
  )

  const handlePlaceSelected = useCallback(
    (place: Partial<Place>) => {
      setDisplayValue(place?.nameLong || '-')
      onMainFilterToggled(false)
      setShowResults(false)
    },
    [onMainFilterToggled]
  )

  const handleMyPositionChanged = useCallback(() => {
    setDisplayValue(`${t('location-selector.current-location')}`)
    onMainFilterToggled(false)
    setShowResults(false)
  }, [t, onMainFilterToggled])

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Escape') {
      onMainFilterToggled(false)
      setShowResults(false)
    }
  }

  const results = useMemo(() => {
    return (
      <GeosearchResults
        forMobile={forMobile}
        searchSession={searchSessionId}
        keyword={keyword}
        displayValue={displayValue}
        location={location}
        onChange={onChange}
        onMyPositionChange={handleMyPositionChanged}
        onAddressSelected={handleAddressSelected}
        onPlaceSelected={handlePlaceSelected}
        onResultCountsChanged={handleResultCountsChanged}
        showCurrentLocationSelector={showCurrentLocationSelector}
      ></GeosearchResults>
    )
  }, [
    displayValue,
    forMobile,
    handleAddressSelected,
    handleMyPositionChanged,
    handlePlaceSelected,
    handleResultCountsChanged,
    keyword,
    location,
    onChange,
    searchSessionId,
    showCurrentLocationSelector,
  ])

  return (
    <>
      <div
        ref={inputContainerRef}
        className={twMerge(
          className,
          'relative z-[3] w-full items-center',
          !forMobile && 'w-[250px]',
          containerClasses
        )}
      >
        <div
          className="relative"
          onMouseEnter={() => (ignoreClickAway.current = true)}
          onMouseLeave={() => (ignoreClickAway.current = false)}
        >
          <div className="absolute inset-y-0 left-0 flex items-center px-3" onClick={handleLocationButtonClick}>
            <FontAwesomeIcon
              icon={faLocationDot}
              width={12}
              className={twMerge('fa-lg', variantConfig.iconClassName)}
            />
          </div>
          <input
            id="geosearch-filter"
            value={displayValue}
            onChange={handleKeywordChange}
            className={twMerge(
              'block w-full rounded pl-9 outline-none',
              variant === GeoSearchVariant.DEFAULT
                ? 'rounded-r-none border border-r-0 border-shades-200 hover:border-r-[1px] hover:border-primary'
                : 'text-primary-600 placeholder-primary-400',
              forMobile ? 'h-[40px] border-b border-b-transparent' : 'h-[42px]',
              isDropdownOpen && forMobile ? 'rounded-b-none border-b border-b-shades-200' : ''
            )}
            onFocus={handleKeywordFocus}
            placeholder={t('location')}
            autoComplete="off"
            onClick={() => {
              ignoreClickAway.current = true
              setShowResults(true)
              onMainFilterToggled(true)
            }}
            onKeyDown={handleKeyDown}
            aria-label={t('listings:search-for-a-location')}
          />
        </div>
        {canClear ? (
          <Button
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              const input = inputContainerRef.current?.querySelector('input')
              if (input) {
                handleKeywordChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
              }
            }}
            className={twMerge(
              'absolute right-2 top-0 border-none bg-transparent p-0 text-xs text-shades-600',
              variantConfig.clearClassName
            )}
            variant="ghostLightStatic"
          >
            {t('listings:filters.clear-search')}
          </Button>
        ) : null}

        {!forMobile && showResults ? (
          <div
            className={twMerge(
              'absolute top-[52px] z-50 w-[360px] rounded-[5px] border border-shades-200 bg-shades-25',
              dropdownContainerClassName
            )}
            ref={dropMenuRef}
            onKeyDown={handleKeyDown}
            onMouseEnter={() => (ignoreClickAway.current = true)}
            onMouseLeave={() => (ignoreClickAway.current = false)}
          >
            {results}
          </div>
        ) : null}
      </div>

      {forMobile ? (
        <MobileDropdown
          id="location-selector-dropdown"
          isOpen={showResults}
          className={
            isDropdownOpen
              ? 'top-full w-[calc(100%-3rem)] rounded-b-[5px] border border-t-0 border-shades-200 drop-shadow-sm'
              : ''
          }
          onCloseCallback={handleDropdownCloseResults}
          autoExpand={resultsCount > 0}
        >
          {results}
        </MobileDropdown>
      ) : null}
    </>
  )
}

export default GeosearchFilter
