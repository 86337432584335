import { faMagnifyingGlass, IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { FlexRow } from '@unreserved-frontend-v2/ui/flex/flex-row'
import { useDropdown } from '@unreserved-frontend-v2/ui/hooks/use-dropdown'
import { SearchInput } from '@unreserved-frontend-v2/ui/search-input/search-input'
import Spinner from '@unreserved-frontend-v2/ui/spinner'

import { BaseInputProps } from '../base-string-input/base-string-input'
import { FlexCol } from '../flex/flex-col'
import ChevronFat from '../icons/chevron-fat'

export interface Option {
  description?: string
  id: string
  name: string
  icon?: IconDefinition
}

export const DROPDOWN_SEARCH_VISIBLE_THRESHOLD = 5

/**
 * A shared prop that all inputs must be able to accept, determined & calculated by a StepInput
 */
export type BaseDropdownProps = BaseInputProps &
  React.InputHTMLAttributes<HTMLDivElement> & {
    dropdownClassName?: string
    selectedOptionClassName?: string
    isRequired?: boolean
    currentValue?: string | null
    options: Option[]
    omitNoneOption?: boolean
    localSearch?: boolean // If true, the dropdown will filter options based on the search input locally.
    showSearch?: boolean // If true, the dropdown will show a search input for filtering in the parent component.
    isSearching?: boolean // True if search results are currently being fetched.
    isFetchingInitialData?: boolean // True if initial data is being fetched.
    readOnly?: boolean
    onSearchChange?: (value: string) => void
  }

export const BaseDropdown = ({
  id,
  onValueChange,
  isErrored,
  isRequired,
  label,
  dropdownClassName,
  selectedOptionClassName,
  containerClassName,
  containerInlineStyle,
  className,
  currentValue,
  placeholder,
  options,
  omitNoneOption,
  localSearch,
  showSearch,
  isSearching,
  isFetchingInitialData,
  readOnly = false,
  onSearchChange = () => undefined,
  ...rest
}: BaseDropdownProps) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<string | null>(currentValue || null)
  const [visibleOptions, setVisibleOptions] = useState<Option[]>(options || [])

  // update options when new values entered
  useEffect(() => {
    setVisibleOptions(options || [])
  }, [options])

  const isSearchVisible = showSearch || (localSearch && (options?.length || 0) > DROPDOWN_SEARCH_VISIBLE_THRESHOLD)

  const {
    dropdownRef,
    isOpen,
    shouldFlip,
    maxHeight,
    isDrawerReady,
    handleToggle,
    handleDropdownEnterKey,
    handleOptionEnterKey,
  } = useDropdown()

  const { name: displayValue, icon: displayIcon } = useMemo(() => {
    const defaultValue = { name: placeholder } as Option

    if (!value) {
      return defaultValue
    }

    return options?.find((option) => option.id === value) || ({} as Option)
  }, [value, options, placeholder])

  const selectOption = useCallback(
    (option: Option | null) => {
      const value = option ? option.id : null

      setValue(value)
      onValueChange(value)
    },
    [onValueChange]
  )

  const handleSearchChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (localSearch) {
        const newKeyword = (e.target as HTMLInputElement).value
        const newOptions = options.filter((option) => option.name.toLowerCase().includes(newKeyword.toLowerCase()))
        setVisibleOptions(newOptions)
      } else {
        const newKeyword = (e.target as HTMLInputElement).value
        onSearchChange(newKeyword)
      }
    },
    [localSearch, onSearchChange, options]
  )

  const handleToggleInternal = useCallback(() => {
    if (!readOnly) {
      handleToggle()
    }
  }, [handleToggle, readOnly])

  return (
    <FlexCol className={containerClassName} style={containerInlineStyle}>
      {label}

      <div
        id={id}
        onClick={handleToggleInternal}
        className={twMerge(
          'relative flex h-10 cursor-pointer items-center justify-between rounded border border-shades-200 pr-8',
          // className must come before error state classes otherwise error state will not show
          className,
          isErrored ? 'border-red-600 bg-red-50' : null,
          readOnly ? 'cursor-not-allowed text-shades-300' : null,
          isFetchingInitialData ? 'cursor-auto' : ''
        )}
        aria-expanded={isOpen || undefined}
        aria-labelledby={`${id}-label`}
        aria-required={isRequired || undefined}
        role="listbox"
        {...rest}
      >
        {isFetchingInitialData ? (
          <div className="w-full">
            <Spinner variant="primary" className="ml-5 text-[3px]" />
          </div>
        ) : (
          <>
            <div
              tabIndex={0}
              onKeyUp={handleDropdownEnterKey}
              className={twMerge(
                'flex h-10 w-full items-center truncate pl-4 outline-primary',
                !value ? 'text-shades' : null,
                isErrored ? 'text-red-600' : null
              )}
            >
              {displayIcon ? (
                <FlexRow className="items-center gap-x-[18px]">
                  <FontAwesomeIcon icon={displayIcon} fontSize={18} className="text-shades" />
                  {displayValue}
                </FlexRow>
              ) : (
                displayValue
              )}
            </div>
            <ChevronFat className="absolute right-4 top-4 -rotate-90" height={8} />
            <div
              ref={dropdownRef}
              className={twMerge(
                'absolute z-[11] w-full overflow-y-auto rounded border border-shades-200 bg-white drop-shadow-md',
                isOpen ? 'block' : 'hidden',
                isDrawerReady ? 'opacity-100' : 'opacity-0',
                shouldFlip ? 'bottom-12' : 'top-12',
                isSearching && isOpen ? 'flex h-[200px] flex-col overflow-y-visible' : '',
                dropdownClassName
              )}
              style={{ maxHeight }}
            >
              {isSearchVisible ? (
                <SearchInput
                  debounceTime={400}
                  placeholder={t('ui:search')}
                  containerClassName="mx-2 my-2"
                  prefixIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                  onChange={handleSearchChange}
                  onClick={(e) => e.stopPropagation()}
                />
              ) : null}

              {!omitNoneOption && !isSearching ? (
                <div
                  role="option"
                  onClick={() => selectOption(null)}
                  onKeyUp={(event) => {
                    handleOptionEnterKey(event)
                    selectOption(null)
                  }}
                  key="none"
                  tabIndex={0}
                  className="mx-2 flex cursor-pointer items-center rounded px-4 py-2.5 outline-primary first:mt-2 last:mb-2 hover:bg-shades-50"
                >
                  <div className="flex flex-col items-start text-shades">{t('ui:dropdown.none')}</div>
                </div>
              ) : null}

              {isSearching && isOpen ? (
                <FlexCol centered={true} className="w-full flex-grow">
                  <Spinner variant="primary" className="" />
                </FlexCol>
              ) : (
                visibleOptions?.map((option) => (
                  <div
                    key={option.id}
                    aria-selected={value === option.id || undefined}
                    role="option"
                    onClick={() => selectOption(option)}
                    onKeyUp={(event) => {
                      handleOptionEnterKey(event)
                      selectOption(option)
                    }}
                    tabIndex={0}
                    className={twMerge(
                      'mx-2 flex cursor-pointer items-center rounded px-4 py-2.5 outline-primary first:mt-2 last:mb-2 hover:bg-shades-50',
                      value === option.id ? selectedOptionClassName : null
                    )}
                  >
                    <div className="flex flex-col items-start">
                      {option.icon ? (
                        <FlexRow className="items-center gap-x-[18px]">
                          <FontAwesomeIcon icon={option.icon} fontSize={18} className="text-shades" />
                          {option.name}
                        </FlexRow>
                      ) : (
                        option.name
                      )}
                      {option.description ? <span className="mt-2 text-shades">{option.description}</span> : null}
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </FlexCol>
  )
}
