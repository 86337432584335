import { SVGProps } from 'react'

const ChevronFat = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 8 12" width={8} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.617 5.954V6a1.42 1.42 0 0 0 .415 1.052l4.535 4.532a1.42 1.42 0 0 0 2.01-2.01L4 6l3.575-3.575A1.42 1.42 0 0 0 5.566.416L1.034 4.948a1.422 1.422 0 0 0-.416 1.006Z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronFat
