import debounce from 'lodash/debounce'
import { forwardRef, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { ConditionalContainer } from '../conditional-container/conditional-container'

export type SearchInputProps = React.HTMLAttributes<HTMLInputElement> & {
  /** Input onchange debounce time in milliseconds */
  debounceTime?: number
  /** Prefix icon at the beginning of the input */
  prefixIcon?: React.ReactElement
  /** Class for the prefixIconContainer if it is used */
  prefixIconContainerClass?: string
  /** Classname for the container, only applicable if a `prefixIcon` exists */
  containerClassName?: string
  /** Classname for the input element itself */
  inputClassName?: string
}

const DEFAULT_DEBOUNCE_TIME = 200

export const SearchInput = forwardRef(
  (
    {
      debounceTime = DEFAULT_DEBOUNCE_TIME,
      prefixIcon,
      prefixIconContainerClass,
      containerClassName,
      onChange,
      ...inputProps
    }: SearchInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const handleOnChange = useMemo(
      () => debounce((e: React.FormEvent<HTMLInputElement>) => onChange?.(e), debounceTime),
      [debounceTime, onChange]
    )

    return (
      <ConditionalContainer
        containerClassName={twMerge('relative flex items-center', containerClassName)}
        isContainerized={!!prefixIcon}
        type="div"
      >
        <>
          {prefixIcon ? (
            <div className={twMerge('absolute left-3.5', prefixIconContainerClass)}>{prefixIcon}</div>
          ) : null}
          <input
            ref={ref}
            data-testid="search-input-input"
            {...inputProps}
            className={twMerge(
              'w-full rounded border border-shades-200 px-4 py-2 focus:outline-none',
              prefixIcon ? 'pl-10' : '',
              inputProps.className
            )}
            onChange={handleOnChange}
          />
        </>
      </ConditionalContainer>
    )
  }
)
