import { faLocationDot } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Place, PlaceType } from '@unreserved-frontend-v2/api/generated/graphql/types'
import useTranslation from 'next-translate/useTranslation'

export type GeosearchResultProps = {
  place: Partial<Place>
}

export function GeosearchResult({ place }: GeosearchResultProps) {
  const { t } = useTranslation()
  const { labelKey, node } = getResultInfo(place)

  return (
    <div className={`flex`}>
      <FontAwesomeIcon className={`pr-4 pt-1`} icon={faLocationDot}></FontAwesomeIcon>
      <div className="flex flex-col">
        <div>{node}</div>
        <div className="text-shades-300 mt-[5px] text-sm">{`${t(labelKey)}`}</div>
      </div>
    </div>
  )
}

export function getResultInfo(place: Partial<Place>): { labelKey: string; node: React.ReactNode } {
  const { nameLong, type } = place

  switch (type) {
    case PlaceType.Country: {
      return {
        labelKey: 'geolocation:location-selector.places.country',
        node: <span data-testid={nameLong} className="font-bold">{`${nameLong}`}</span>,
      }
    }

    case PlaceType.Region: {
      return {
        labelKey: 'geolocation:location-selector.places.region',
        node: <span data-testid={nameLong} className="font-bold">{`${nameLong}`}</span>,
      }
    }

    case PlaceType.City: {
      return {
        labelKey: 'geolocation:location-selector.places.city',
        node: <span data-testid={nameLong} className="font-bold">{`${nameLong}`}</span>,
      }
    }

    case PlaceType.Street: {
      return {
        labelKey: 'geolocation:location-selector.places.street',
        node: nameLong,
      }
    }

    case PlaceType.MetroArea: {
      return {
        labelKey: 'geolocation:location-selector.places.area',
        node: nameLong,
      }
    }

    case PlaceType.MacroNeighbourhood: {
      return {
        labelKey: 'geolocation:location-selector.places.area',
        node: nameLong,
      }
    }

    case PlaceType.Neighbourhood:
    case PlaceType.SubNeighbourhood: {
      return {
        labelKey: 'geolocation:location-selector.places.neighbourhood',
        node: nameLong,
      }
    }
  }

  return { labelKey: '', node: <>${`-`}</> }
}
