import { UseQueryOptions } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import { ClientError } from 'graphql-request'
import { useMemo } from 'react'

import { API_ENDPOINT } from '../constants'
import {
  GetGeosearchResultsQuery,
  useGetGeosearchResultsQuery,
} from '../generated/graphql/get-geosearch-results.graphql'
import { Query } from '../generated/graphql/types'

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

export function useGetGeosearchResults(
  query: string,
  sessionToken: string,
  options: UseQueryOptions<GetGeosearchResultsQuery, ClientError, Query> = {}
) {
  const variables = useMemo(() => {
    return {
      autocompleteInput: { query, sessionToken },
      placesInput: { input: query },
    }
  }, [query, sessionToken])

  const { data, refetch, isLoading, isError, fetchStatus } = useGetGeosearchResultsQuery<Query, ClientError>(
    new GraphQLClient(API_ENDPOINT),
    variables,
    { ...options }
  )

  return useMemo(() => {
    return {
      addresses: data?.addressAutocomplete,
      places: data?.placeAutocomplete,
      refetch,
      isLoading,
      isRefetching: fetchStatus === 'fetching',
      isError,
    }
  }, [data?.addressAutocomplete, data?.placeAutocomplete, fetchStatus, isError, isLoading, refetch])
}
