import React from 'react'
import { twMerge } from 'tailwind-merge'

export interface ConditionalContainerProps {
  containerClassName?: string
  type: 'div' | 'span'
  children: React.ReactElement
  /** Whether or not the children should be wrapped in a container */
  isContainerized: boolean
}

/**
 * A helper component that wraps the children in a container if necessary
 * (useful for conditional rendering to reduce the size of the DOM when unnecessary)
 */

export function ConditionalContainer({
  containerClassName = '',
  isContainerized,
  children,
  type,
}: ConditionalContainerProps) {
  return isContainerized ? React.createElement(type, { className: twMerge(containerClassName) }, children) : children
}
