import React, { ReactNode, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'

import { closeVirtualKeyboard } from './utils'

export interface MobileDropdownProps {
  /** The ID of the dropdown being implemented */
  id: string
  /** Whether or not the dropdown is considered to be opened */
  isOpen: boolean
  /** True if the dropdown should expand to the non-default size. */
  autoExpand?: boolean
  /** Callback to close this dropdown externally */
  onCloseCallback: () => void
  /** Overriding classnames for the container */
  className?: string
  /** Overriding classnames for the inner container */
  childClassName?: string
  /** Default children to be rendered */
  children: ReactNode
}

export function MobileDropdown({
  id,
  isOpen,
  className = '',
  childClassName = '',
  onCloseCallback,
  children,
}: MobileDropdownProps) {
  // Preventing parent propagation in case of nesting, closing virtual keyboard
  const handleTouchMoveDropdown = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
    closeVirtualKeyboard()
  }

  const handleCloseDropdown = useCallback(
    (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement> | TouchEvent) => {
      e.stopPropagation()
      closeVirtualKeyboard()
      onCloseCallback()
    },
    [onCloseCallback]
  )

  return (
    <>
      <div
        id={id}
        className={twMerge(
          'w-100 absolute z-[3] overflow-y-visible bg-shades-25',
          !isOpen ? 'pointer-events-none opacity-0' : 'opacity-100',
          className
        )}
        style={{
          transition: 'opacity 100ms, height 100ms ease-out',
        }}
        onTouchMove={handleTouchMoveDropdown}
      >
        <div className={childClassName}>{isOpen && children}</div>
      </div>

      <div
        data-testid={`${id}-blocker`}
        className={twMerge('fixed top-0 left-0 z-[2] h-[100vh] w-[100vw]', !isOpen ? 'hidden' : '')}
        onClick={handleCloseDropdown}
        onTouchMove={handleCloseDropdown}
      />
    </>
  )
}

export default MobileDropdown
